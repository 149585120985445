<template>
  <div
      :id="treeID"
      class="aside"
      @mouseover="cascaderMouse"
      @click.stop=""
  >
    <div class="lock">
      <Icon type="ios-lock-outline" size="18" @click.stop="fixedTree(false)" v-if="fixedMenu"/>
      <Icon type="ios-unlock-outline" size="18" @click.stop="fixedTree(true)" v-else/>
    </div>
    <Tooltip placement="right-start" style="width:100%">
      <div slot="content" style="width: 200px;white-space: normal">
        由于菜单太多，请输入关键字进行筛选
      </div>
      <Select
          v-model="nodeId"
          filterable
          @on-change="getTree"
          placeholder="点击列表/输入搜索"
          :remote-method="searchLocal"
      >
        <!-- 防止报错，使用了index -->
        <Option v-for="(item,index) in nodeList" :value="item.value" :key="index">{{ item.label }}</Option>
      </Select>
    </Tooltip>
    <Tree
        v-if="cascaderFocusShow"
        :data="treeData"
        @on-select-change="handleTree"
        :load-data="loadData"
        :style="height!=null ? 'height:'+height+'px':''"
        class="fadeInDown animated"
    ></Tree>
  </div>
</template>

<script>
export default {
  name: "tree-list",
  props: {
    fixedmenu: Boolean,
    treeID: String,//
    fixedContentID: String,//,
    height: String
  },
  data() {
    return {
      //tree菜单显示与隐藏
      cascaderFocusShow: false,
      controllerForm: {
        searchList: [],
      },
      fixedMenu: false,
      treeData: [],
      nodeList: [],
      nodeId: this.nodeId ? this.nodeId : [],
      sysModuleId: "",
      name: '',
      parentId: "-1",
    };
  },
  created() {
  },
  mounted() {
    this.fixedTree(this.fixedmenu);
    this.$("body").on("click", this.pageClick);
    this.selectNode();
  },
  methods: {
    //本地搜索
    searchLocal(keyword) {
      console.log(keyword)
      this.nodeList = this.controllerForm.searchList.filter(item => item.label.toLowerCase().indexOf(keyword.toLowerCase()) > -1);
    },
    //固定菜单树tree
    fixedTree(bool) {
      var that = this;
      if (bool) {
        if (this.fixedContentID != null) that.$("#" + this.fixedContentID).animate({left: that.$("#" + this.treeID).width() + 10}, 200);
        that.$("#" + this.treeID).css({
          "position": "absolute",
          "left": that.$("#" + this.treeID).offset().left
        }).animate({
          top: (this.fixedContentID != null ? that.$("#" + this.fixedContentID).offset().top + 10 : 0),
          left: 8
        });
      } else {
        that.$("#" + this.fixedContentID).animate({left: 0}, 200);
        that.$("#" + this.treeID).css({"position": "relative"}).animate({top: 0, left: 0});
      }
      this.fixedMenu = bool;
      this.cascaderFocusShow = bool;

    },
    //鼠标悬停显示tree菜单
    cascaderMouse() {
      this.cascaderFocusShow = true;
    },
    //页面点击隐藏tree菜单
    pageClick() {
      //判断是否固定菜单
      if (!this.fixedMenu) {
        this.cascaderFocusShow = false;
      }

    },
    selectNode() {
      // let params = {
      //   parentId: '-1',
      // };
      // this.$get("/auth/api/auth/pc/systemModule/cascade", params)
      //     .then((res) => {
      //       if (res.code === "200" && res.dataList != null) {
      //         this.nodeList = res.dataList.map((item) => {
      //           return {
      //             value: item.sysModuleId,
      //             label: item.name
      //           };
      //         });
      //         this.controllerForm.searchList = JSON.parse(JSON.stringify(this.nodeList));
      //       }
      //     })
      //     .catch((err) => {
      //     });
    },

    handleTree(node) {
      var data = {};
      if (node && node.length>0) {
        data = {
          parentId: node[0].sysModuleId,
          parentParentId: node[0].parentId,
        };
      }
      this.$emit("handleData", data);
    },

    async getTree(val) {
      this.treeData = [];
      this.handleTree();
      if (val && val.length > 0) {
        await  this.$get("/auth/api/auth/pc/systemModule/cascade", {
          parentId: this.parentId,
          systemId:'13' || this.$route.query.systemId
        }).then((re) => {
          if (re && re.code === "200" && re.dataList != null) {
            let temp = re.dataList.map((i) => {
              if (i.hasChild == 2) {
                return {
                  title: i.name,
                  parentId: i.parentId,
                  sysModuleId: i.sysModuleId,
                  children: [],
                  loading: false,
                }
              } else {
                return {
                  title: i.name,
                  parentId: i.parentId,
                  sysModuleId: i.sysModuleId,
                }
              }
            });
            this.treeData = temp;
          }
        });
      }
    },
    loadData(item, callback) {
      let params = {
        parentId: item.sysModuleId,
        systemId:'13' || this.$route.query.systemId
      };
      this.$get("/auth/api/auth/pc/systemModule/cascade", params)
          .then((res) => {
            if (res.code === "200" && res.dataList && res.dataList.length) {
              let arr = res.dataList.map((i) => {
                if (i.hasChild == 2) {
                  return {
                    title: i.name,
                    parentId: i.parentId,
                    sysModuleId: i.sysModuleId,
                    children: [],
                    loading: false,
                  }
                } else {
                  return {
                    title: i.name,
                    parentId: i.parentId,
                    sysModuleId: i.sysModuleId,
                  }
                }
              });
              item.children = arr;
              callback(item.children);
            }
          })
          .catch((err) => {
          });
    },

  },
};
</script>

<style lang="less" scoped>
.ivu-layout-sider {
  background: white;
}

.aside {
  margin: 0 5px;
  min-width: 200px;
  position: relative;

  /deep/ .ivu-select {
    position: absolute;
    left: 0;
    top: -15px;
    z-index: 999;
    min-width: 200px;

    input {
      padding: 0 26px;
    }

    .ivu-select-selection {
      background-color: #e8eaec !important;
      border: 1px solid #dcdee2;
    }
  }

  .lock {
    position: absolute;
    left: 6px;
    top: 5px;
    z-index: 1000;
    cursor: pointer;
  }

  .ivu-tree {
    height: calc(100vh - 100px);
    min-width: 200px;
    width: 100%;
    position: absolute;
    background: #fbfbfb;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 2px #ddd;
    border-radius: 0 0 5px 5px;
    z-index: 998;
    top: 30px;
    padding: 10px;
    overflow-y: auto;

    /deep/ ul {
      text-align: left;
      line-height: 1.3em;

      .ivu-tree-title-selected,
      .ivu-tree-title-selected:hover {
        background-color: #82d2af;
      }
    }
  ;

    /deep/ div {
      text-align: center;
    }
  }
}
</style>
